import React from "react";

import AddIcon from "@material-ui/icons/Add";
import Box from "@material-ui/core/Box";
import DateFnsUtils from "@date-io/date-fns";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import { KeyboardDateTimePicker } from "@material-ui/pickers/";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import { parse } from "date-fns";
import RemoveIcon from "@material-ui/icons/Remove";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import Hidden from "@material-ui/core/Hidden";

import CustomAccordion from "../display/CustomAccordion";

const useStyles = makeStyles(() => ({
  root: { flexWrap: "wrap" },
}));

const TransportationPanel = (props) => {
  const classes = useStyles();
  const maxInputCollection = 5;
  const minInputCollection = 2;

  const [count, dispatch] = React.useReducer((count, action) => {
    switch (action) {
      case "add":
        return count + 1;
      case "remove":
        return count - 1;
      default:
        throw new Error();
    }
  }, 2);

  const [value, setValue] = React.useState(
    [...Array(maxInputCollection)].map((_, index) => ({
      [`meetingPoint${index}`]: "",
      [`vehicle${index}`]: "",
      [`arrivalDate${index}`]: null,
      [`ticketCode${index}`]: "",
      [`transferType${index}`]: "",
      [`transportType${index}`]: "",
      [`flightCompany${index}`]: "",
      [`especialTranport${index}`]: "506",
      [`especialObs${index}`]: "",
    }))
  );

  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const handleChange = (event, index = null) => {
    const input = event.target;

    setValue((previousValues) => {
      let newValues = previousValues;

      newValues[index][`${input.name}${index}`] = input.value;

      return newValues;
    });

    forceUpdate();
  };

  return props.transportationFields && props.event && props.dealFields ? (
    <CustomAccordion
      AccordionSummary={<Typography variant="h6">Transporte</Typography>}
      AccordionProps={{ defaultExpanded: true }}
      AccordionActions={
        <React.Fragment>
          {count > minInputCollection ? (
            <Fab
              variant="extended"
              color="secondary"
              size="small"
              onClick={() => dispatch("remove")}
            >
              <RemoveIcon />
            </Fab>
          ) : undefined}
          {count < maxInputCollection ? (
            <Fab
              variant="extended"
              color="primary"
              size="small"
              onClick={() => dispatch("add")}
            >
              <AddIcon />
            </Fab>
          ) : undefined}
        </React.Fragment>
      }
      AccordionDetails={{ className: classes.root }}
    >
      {[...Array(count)].map((_, index) =>
        React.cloneElement(
          <Box
            paddingY={1}
            paddingBottom={2}
            paddingX={1}
            marginY={1}
            style={{ width: "100%" }}
          >
            <fieldset name="transportationFields">
              <Typography variant="subtitle2" display="block">
                {["Trecho", index + 1].join(" ")}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <FormControl fullWidth>
                    <InputLabel>
                      {props.transportationFields.PROPERTY_528.NAME}
                    </InputLabel>
                    <Select
                      name="transportType"
                      value={
                        index === 0
                          ? "458"
                          : index === 1
                          ? "460"
                          : value[index][`transportType${index}`]
                      }
                      onChange={(event) => handleChange(event, index)}
                    >
                      {Object.entries(
                        props.transportationFields.PROPERTY_528
                          .DISPLAY_VALUES_FORM
                      ).map((listOption) => (
                        <MenuItem value={listOption[0]} key={listOption[0]}>
                          {listOption[1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <FormControl fullWidth>
                    <InputLabel>
                      {props.transportationFields.PROPERTY_530.NAME}
                    </InputLabel>
                    <Select
                      name="transferType"
                      value={value[index][`transferType${index}`]}
                      onChange={(event) => handleChange(event, index)}
                    >
                      {Object.entries(
                        props.transportationFields.PROPERTY_530
                          .DISPLAY_VALUES_FORM
                      ).map((listOption) => (
                        <MenuItem value={listOption[0]} key={listOption[0]}>
                          {listOption[1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                  <FormControl fullWidth>
                    <InputLabel>
                      {props.transportationFields.PROPERTY_534.NAME}
                    </InputLabel>
                    <Select
                      name="meetingPoint"
                      value={
                        value[index][`transferType${index}`] === "462" ||
                        value[index][`transferType${index}`] === "500"
                          ? ""
                          : value[index][`meetingPoint${index}`]
                      }
                      onChange={(event) => handleChange(event, index)}
                      disabled={
                        value[index][`transferType${index}`] === "462" ||
                        value[index][`transferType${index}`] === "500"
                      }
                    >
                      {props.meetingPoints.map((meetingPoint) => (
                        <MenuItem
                          value={Object.values(meetingPoint.PROPERTY_644)[0]}
                          key={Object.values(meetingPoint.PROPERTY_644)[0]}
                        >
                          {
                            props.meetingPointsFields.PROPERTY_644
                              .DISPLAY_VALUES_FORM[
                              Object.values(meetingPoint.PROPERTY_644)[0]
                            ]
                          }
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Campo de veículo "removido". */}
                {/* <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <TextField
                    name="vehicle"
                    label="Veículo"
                    value={
                      value[index][`transferType${index}`] === "462" ||
                      value[index][`transferType${index}`] === "500"
                        ? ""
                        : value[index][`meetingPoint${index}`]
                        ? Object.values(
                            props.meetingPoints.filter(
                              (meetingPoint) =>
                                Object.values(meetingPoint.PROPERTY_644)[0] ===
                                value[index][`meetingPoint${index}`]
                            )[0].PROPERTY_522
                          )[0]
                        : value[index][`vehicle${index}`]
                    }
                    onChange={(event) => handleChange(event, index)}
                    disabled={
                      value[index][`transferType${index}`] === "462" ||
                      value[index][`transferType${index}`] === "500"
                    }
                    fullWidth
                    select
                  >
                    {props.meetingPoints
                      .filter(
                        (meetingPoint) =>
                          Object.values(meetingPoint.PROPERTY_644)[0] ===
                          value[index][`meetingPoint${index}`]
                      )
                      .map((meetingPoint) => (
                        <MenuItem
                          value={Object.values(meetingPoint.PROPERTY_522)[0]}
                          key={Object.values(meetingPoint.PROPERTY_522)[0]}
                        >
                          {
                            props.meetingPointsFields.PROPERTY_522
                              .DISPLAY_VALUES_FORM[
                              Object.values(meetingPoint.PROPERTY_522)[0]
                            ]
                          }
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid> */}

                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <TextField
                    name="flightCompany"
                    label="Companhia aérea"
                    value={
                      value[index][`transferType${index}`] === "462" ||
                      value[index][`vehicle${index}`] === "466" ||
                      value[index][`transferType${index}`] === "500"
                        ? ""
                        : value[index][`flightCompany${index}`]
                    }
                    onChange={(event) => handleChange(event, index)}
                    disabled={
                      value[index][`transferType${index}`] === "462" ||
                      value[index][`vehicle${index}`] === "466" ||
                      value[index][`transferType${index}`] === "500"
                    }
                    fullWidth
                  />
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <TextField
                    name="ticketCode"
                    label={props.transportationFields.PROPERTY_538.NAME}
                    value={
                      value[index][`transferType${index}`] === "462" ||
                      value[index][`vehicle${index}`] === "466" ||
                      value[index][`transferType${index}`] === "500"
                        ? ""
                        : value[index][`ticketCode${index}`]
                    }
                    onChange={(event) => handleChange(event, index)}
                    disabled={
                      value[index][`transferType${index}`] === "462" ||
                      value[index][`vehicle${index}`] === "466" ||
                      value[index][`transferType${index}`] === "500"
                    }
                    fullWidth
                  />
                </Grid>

                <Grid item xs sm md lg xl>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      name="arrivalDate"
                      variant="inline"
                      format="dd/MM/yyyy HH:mm"
                      label={props.meetingPointsFields.PROPERTY_542.NAME}
                      ampm={false}
                      disableToolbar
                      autoOk
                      hideTabs
                      invalidDateMessage="Data inválida"
                      value={
                        value[index][`transferType${index}`] === "462" ||
                        value[index][`vehicle${index}`] === "466" ||
                        value[index][`transferType${index}`] === "500"
                          ? null
                          : value[index][`meetingPoint${index}`]
                          ? parse(
                              Object.values(
                                props.meetingPoints.filter(
                                  (meetingPoint) =>
                                    Object.values(
                                      meetingPoint.PROPERTY_644
                                    )[0] ===
                                    value[index][`meetingPoint${index}`]
                                )[0].PROPERTY_542
                              )[0],
                              "dd/MM/yyyy HH:mm:ss",
                              new Date()
                            )
                          : value[index][`arrivalDate${index}`]
                      }
                      disabled={
                        value[index][`transferType${index}`] === "462" ||
                        value[index][`vehicle${index}`] === "466" ||
                        value[index][`transferType${index}`] === "500"
                      }
                      onChange={(date) =>
                        handleChange(
                          {
                            target: { name: "arrivalDate", value: date },
                          },
                          index
                        )
                      }
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <TextField
                    name="especialTranport"
                    label={props.transportationFields.PROPERTY_550.NAME}
                    value={value[index][`especialTranport${index}`]}
                    onChange={(event) => handleChange(event, index)}
                    fullWidth
                    select
                  >
                    {Object.entries(
                      props.transportationFields.PROPERTY_550
                        .DISPLAY_VALUES_FORM
                    ).map((listOption) => (
                      <MenuItem value={listOption[0]} key={listOption[0]}>
                        {listOption[1]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs sm md lg xl>
                  <TextField
                    name="especialObs"
                    label={props.transportationFields.PROPERTY_552.NAME}
                    value={value[index][`especialObs${index}`]}
                    onChange={(event) => handleChange(event, index)}
                    // disabled={
                    //   value[index][`transferType${index}`] === "462" ||
                    //   value[index][`transferType${index}`] === "500"
                    // }
                    disabled={
                      value[index][`especialTranport${index}`] === "506"
                    }
                    fullWidth
                  ></TextField>
                </Grid>
              </Grid>
            </fieldset>
          </Box>,
          { key: index }
        )
      )}
    </CustomAccordion>
  ) : (
    <CustomAccordion disabled />
  );
};

export default TransportationPanel;
