import React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import KeyboardDateTimePickerStateful from "../container/KeyboardDateTimePickerStateful";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: theme.spacing(0.5),
  },
}));

const SpecialBookingPanel = (props) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-end" spacing={2}>
      <Grid item>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePickerStateful
            initialvalue={new Date(props.event.UF_CRM_1523902149)}
            name="checkIn"
            variant="inline"
            format="dd/MM/yyyy HH:mm"
            label="Check-in"
            ampm={false}
            disableToolbar
            autoOk
            hideTabs
            invalidDateMessage="Horário inválido"
            disabled={!props.specialBooking}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePickerStateful
            initialvalue={new Date(props.event.UF_CRM_1523902250)}
            name="checkOut"
            variant="inline"
            format="dd/MM/yyyy HH:mm"
            label="Check-out"
            ampm={false}
            disableToolbar
            autoOk
            hideTabs
            invalidDateMessage="Horário inválido"
            disabled={!props.specialBooking}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              name="specialBooking"
              className={classes.checkbox}
              value={props.specialBooking}
              onChange={(event) =>
                props.setSpecialBooking(event.target.checked)
              }
            />
          }
          label="Reserva especial"
        />
      </Grid>
    </Grid>
  );
};

export default SpecialBookingPanel;
