import React from "react";

import AddIcon from "@material-ui/icons/Add";
import Box from "@material-ui/core/Box";
import DateFnsUtils from "@date-io/date-fns";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import RemoveIcon from "@material-ui/icons/Remove";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import CreditCardPaymentInputCollection from "./CreditCardPaymentInputCollection";
import CustomAccordion from "./CustomAccordion";
import KeyboardDatePicker from "../container/KeyboardDatePicker";
import TransferPaymentInputCollection from "./TransferPaymentInputCollection";

const useStyles = makeStyles((theme) => ({
  flexWrap: {
    flexWrap: "wrap",
  },
  marginChild: {
    "& > *": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(5),
    },
  },
}));

const PaymentPanel = (props) => {
  const classes = useStyles();
  const maxInputCollection = 2;
  const minInputCollection = 1;

  const [paymentMethods, setPaymentMethods] = React.useState(
    [...Array(maxInputCollection)].map((_, index) => {
      return {
        [`entry${index}`]: "",
        [`balance${index}`]: "",
      };
    })
  );
  const [errors, setErrors] = React.useState(
    [...Array(maxInputCollection)].map((_, index) => {
      return {
        [`entry${index}`]: "",
        [`balance${index}`]: "",
      };
    })
  );
  const [count, dispatch] = React.useReducer(
    (count, action) => {
      if (action.add) {
        return { ...count, [action.where]: count[action.where] + 1 };
      } else {
        return { ...count, [action.where]: count[action.where] - 1 };
      }
    },
    { entry: 1, balance: 1 }
  );
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const [balances, setBalances] = React.useState([
    ...Array(maxInputCollection).fill(""),
  ]);

  const handleChange = (event, index) => {
    const input = event.target;

    if (input.name === "balancePaymentAmount") {
      setBalances((previousBalances) => {
        const newBalances = previousBalances;

        newBalances[0] = input.value;

        return newBalances;
      });
    } else {
      if (input.name === "entryPaymentMethod") {
        setPaymentMethods((previousValues) => {
          const newPaymentMethods = previousValues;
          newPaymentMethods[index][`entry${index}`] = input.value;

          return newPaymentMethods;
        });
      } else {
        setPaymentMethods((previousValues) => {
          const newPaymentMethods = previousValues;
          newPaymentMethods[index][`balance${index}`] = input.value;

          return newPaymentMethods;
        });
      }
    }

    forceUpdate();
  };

  const handleBlur = (event, index) => {
    const input = event.target;
    input.value = input.value.replaceAll(",", ".");

    if (Number(props.contact.UF_CRM_1613582880) < Number(input.value)) {
      const error = "Entrada + saldo excede saldo VIP";

      setErrors((previousErrors) => {
        const newErrors = previousErrors;

        if (
          paymentMethods[index][`entry${index}`] === "vip" &&
          input.name === "entryPaymentAmount"
        ) {
          newErrors[index][`entry${index}`] = error;
        } else if (
          paymentMethods[index][`balance${index}`] === "vip" &&
          input.name === "balancePaymentAmount"
        ) {
          newErrors[index][`balance${index}`] = error;
        } else if (input.name === "entryPaymentAmount") {
          newErrors[index][`entry${index}`] = "";
        } else if (input.name === "balancePaymentAmount") {
          newErrors[index][`balance${index}`] = "";
        }

        return newErrors;
      });
    } else {
      setErrors((previousErrors) => {
        const newErrors = previousErrors;

        if (input.name === "entryPaymentAmount") {
          newErrors[index][`entry${index}`] = "";
        } else if (input.name === "balancePaymentAmount") {
          newErrors[index][`balance${index}`] = "";
        }

        return newErrors;
      });
    }

    if (input.name !== "balancePaymentAmount") {
      setBalances((previousBalances) => {
        const newBalances = previousBalances;

        newBalances[0] = String(
          Number(props.registrationTotal) - Number(input.value)
        );

        return newBalances;
      });
    }

    forceUpdate();
  };

  return props.event && props.paymentInformationFields && count ? (
    <CustomAccordion
      AccordionSummary={<Typography variant="h6">Pagamento</Typography>}
      AccordionProps={{ defaultExpanded: true }}
      AccordionDetails={{ className: classes.flexWrap }}
    >
      {[...Array(count.entry)].map((_, index) =>
        React.cloneElement(
          <Box
            paddingY={1}
            paddingBottom={2}
            paddingX={1}
            marginY={1}
            style={{ width: "100%" }}
          >
            <fieldset name="entryPaymentFields">
              <Grid container spacing={2}>
                <Grid item xs sm md lg xl>
                  <TextField
                    label={`Entrada ${index + 1}`}
                    name="entryPaymentAmount"
                    placeholder="0.00"
                    defaultValue=""
                    fullWidth
                    onBlur={(event) => handleBlur(event, index)}
                    error={errors[index][`entry${index}`] !== ""}
                    helperText={
                      errors[index][`entry${index}`] ||
                      "Usar ponto para separador de centavos"
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {
                            new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                              .format("")
                              .split(/\s/)[0]
                          }
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs sm md lg xl>
                  <FormControl fullWidth>
                    <InputLabel>Forma de pagamento</InputLabel>
                    <Select
                      name="entryPaymentMethod"
                      value={paymentMethods[index][`entry${index}`]}
                      onChange={(event) => handleChange(event, index)}
                    >
                      {Object.entries(
                        props.paymentInformationFields.PROPERTY_450
                          .DISPLAY_VALUES_FORM
                      )
                        .map((paymentMethod, index) => (
                          <MenuItem key={index} value={paymentMethod[0]}>
                            {paymentMethod[1]}
                          </MenuItem>
                        ))
                        .concat([
                          <MenuItem value="vip" key="vip">
                            VIP
                          </MenuItem>,
                        ])}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs sm md lg xl>
                  <FormControl fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        name="paymentDate"
                        variant="inline"
                        format="dd/MM/yyyy"
                        label="Data de pagamento"
                        disableToolbar
                        autoOk
                        hideTabs
                        invalidDateMessage="Data inválida"
                        state={new Date()}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>

                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <TextField
                    name="installments"
                    label="Parcelas"
                    defaultValue="410"
                    select
                    fullWidth
                  >
                    {Object.entries(
                      props.paymentInformationFields.PROPERTY_518
                        .DISPLAY_VALUES_FORM
                    )
                      .map((installments) => (
                        <MenuItem key={installments[0]} value={installments[0]}>
                          {installments[1]}
                        </MenuItem>
                      ))
                      .slice(
                        0,
                        paymentMethods[index][`entry${index}`] === "348"
                          ? Number(props.event.UF_CRM_1616538329) || undefined
                          : paymentMethods[index][`entry${index}`] === "346"
                          ? Number(props.event.UF_CRM_1616538329) || undefined
                          : paymentMethods[index][`entry${index}`] === "434"
                          ? Number(props.event.UF_CRM_1616538329) || undefined
                          : -11
                      )}
                  </TextField>
                </Grid>
              </Grid>
              <Box marginBottom={5} width="100%">
                {paymentMethods[index][`entry${index}`] === "350" ? (
                  <TransferPaymentInputCollection
                    bankBrands={
                      props.paymentInformationFields.PROPERTY_460
                        .DISPLAY_VALUES_FORM
                    }
                    storedPaymentData={props.storedPaymentData}
                  />
                ) : paymentMethods[index][`entry${index}`] === "436" ||
                  paymentMethods[index][`entry${index}`] === "348" ? (
                  <CreditCardPaymentInputCollection
                    creditCardBrands={
                      props.paymentInformationFields.PROPERTY_492
                        .DISPLAY_VALUES_FORM
                    }
                    paymentMethod={paymentMethods[index][`entry${index}`]}
                    storedPaymentData={props.storedPaymentData}
                  />
                ) : paymentMethods[index][`entry${index}`] === "434" ? (
                  <TransferPaymentInputCollection
                    bankBrands={
                      props.paymentInformationFields.PROPERTY_460
                        .DISPLAY_VALUES_FORM
                    }
                    storedPaymentData={props.storedPaymentData}
                  >
                    {
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <TextField
                          name="checkNumber"
                          label="Número do cheque"
                          fullWidth
                        />
                      </Grid>
                    }
                  </TransferPaymentInputCollection>
                ) : undefined}
              </Box>
            </fieldset>
          </Box>,
          { key: index }
        )
      )}

      <Box width="100%" textAlign="right" className={classes.marginChild}>
        {count.entry < maxInputCollection ? (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => dispatch({ add: true, where: "entry" })}
          >
            <AddIcon />
          </Fab>
        ) : undefined}

        {count.entry > minInputCollection ? (
          <Fab
            variant="extended"
            color="secondary"
            size="small"
            onClick={() => dispatch({ remove: true, where: "entry" })}
          >
            <RemoveIcon />
          </Fab>
        ) : undefined}
      </Box>

      {[...Array(count.balance)].map((_, index) => {
        const propValue =
          index === 0 ? { value: balances[index] } : { defaultValue: "" };
        const change = index === 0 ? { onChange: handleChange } : {};
        return React.cloneElement(
          <Box
            paddingY={1}
            paddingBottom={2}
            paddingX={1}
            marginY={1}
            style={{ width: "100%" }}
          >
            <fieldset name="balancePaymentFields">
              <Grid container spacing={2}>
                <Grid item xs sm md lg xl>
                  <TextField
                    label={`Saldo ${index + 1}`}
                    name="balancePaymentAmount"
                    placeholder="0.00"
                    fullWidth
                    {...propValue}
                    {...change}
                    onBlur={(event) => handleBlur(event, index)}
                    error={errors[index][`balance${index}`] !== ""}
                    helperText={
                      errors[index][`balance${index}`] ||
                      "Usar ponto para separador de centavos"
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {
                            new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                              .format("")
                              .split(/\s/)[0]
                          }
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs sm md lg xl>
                  <FormControl fullWidth>
                    <InputLabel>Forma de pagamento</InputLabel>
                    <Select
                      name="balancePaymentMethod"
                      value={paymentMethods[index][`balance${index}`]}
                      onChange={(event) => handleChange(event, index)}
                    >
                      {Object.entries(
                        props.paymentInformationFields.PROPERTY_450
                          .DISPLAY_VALUES_FORM
                      )
                        .filter((paymentMethod) => paymentMethod[0] !== "436")
                        .map((paymentMethod) => (
                          <MenuItem
                            key={paymentMethod[0]}
                            value={paymentMethod[0]}
                          >
                            {paymentMethod[1]}
                          </MenuItem>
                        ))
                        .concat([
                          <MenuItem value="vip" key="vip">
                            VIP
                          </MenuItem>,
                        ])}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs sm md lg xl>
                  <FormControl fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        name="paymentDate"
                        variant="inline"
                        format="dd/MM/yyyy"
                        label="Data de pagamento"
                        disableToolbar
                        autoOk
                        hideTabs
                        invalidDateMessage="Data inválida"
                        state={null}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>

                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <TextField
                    name="installments"
                    label="Parcelas"
                    defaultValue="410"
                    select
                    fullWidth
                  >
                    {Object.entries(
                      props.paymentInformationFields.PROPERTY_518
                        .DISPLAY_VALUES_FORM
                    )
                      .map((installments) => (
                        <MenuItem key={installments[0]} value={installments[0]}>
                          {installments[1]}
                        </MenuItem>
                      ))
                      .slice(
                        0,
                        paymentMethods[index][`balance${index}`] === "348"
                          ? Number(props.event.UF_CRM_1616538329) || undefined
                          : paymentMethods[index][`balance${index}`] === "346"
                          ? Number(props.event.UF_CRM_1616538329) || undefined
                          : paymentMethods[index][`balance${index}`] === "434"
                          ? Number(props.event.UF_CRM_1616538329) || undefined
                          : -11
                      )}
                  </TextField>
                </Grid>
              </Grid>

              <Box marginBottom={5} width="100%">
                {paymentMethods[index][`balance${index}`] === "350" ? (
                  <TransferPaymentInputCollection
                    bankBrands={
                      props.paymentInformationFields.PROPERTY_460
                        .DISPLAY_VALUES_FORM
                    }
                    storedPaymentData={props.storedPaymentData}
                  />
                ) : paymentMethods[index][`balance${index}`] === "348" ? (
                  <CreditCardPaymentInputCollection
                    creditCardBrands={
                      props.paymentInformationFields.PROPERTY_492
                        .DISPLAY_VALUES_FORM
                    }
                    storedPaymentData={props.storedPaymentData}
                    paymentMethod={paymentMethods[index][`balance${index}`]}
                  />
                ) : paymentMethods[index][`balance${index}`] === "434" ? (
                  <TransferPaymentInputCollection
                    bankBrands={
                      props.paymentInformationFields.PROPERTY_460
                        .DISPLAY_VALUES_FORM
                    }
                    storedPaymentData={props.storedPaymentData}
                  >
                    {
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <TextField
                          name="checkNumber"
                          label="Número do cheque"
                          fullWidth
                        />
                      </Grid>
                    }
                  </TransferPaymentInputCollection>
                ) : undefined}
              </Box>
            </fieldset>
          </Box>,
          { key: index }
        );
      })}

      <Box width="100%" textAlign="right" className={classes.marginChild}>
        {count.balance < maxInputCollection ? (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => dispatch({ add: true, where: "balance" })}
          >
            <AddIcon />
          </Fab>
        ) : undefined}

        {count.balance > minInputCollection ? (
          <Fab
            variant="extended"
            color="secondary"
            size="small"
            onClick={() => dispatch({ remove: true, where: "balance" })}
          >
            <RemoveIcon />
          </Fab>
        ) : undefined}
      </Box>
    </CustomAccordion>
  ) : (
    <CustomAccordion disabled />
  );
};

export default PaymentPanel;
