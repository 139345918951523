import React from "react";

import Fab from "@material-ui/core/Fab";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  b1: {
    bottom: "75px",
    right: "15px",
    position: "fixed",
  },
  b2: {
    bottom: "15px",
    right: "15px",
    position: "fixed",
  },
}));

const ActionFloaters = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Fab
        className={classes.b1}
        name="calculateTotal"
        type="submit"
        color="primary"
        variant="extended"
      >
        Calcular
      </Fab>
      <Fab
        className={classes.b2}
        name="submitRegistration"
        type="submit"
        color="primary"
        variant="extended"
      >
        Finalizar
      </Fab>
    </React.Fragment>
  );
};

export default ActionFloaters;
