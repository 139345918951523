import React from "react";

import Typography from "@material-ui/core/Typography";

import CustomAccordion from "./CustomAccordion";
import FriendsPicker from "../container/FriendsPicker";

const FriendshipPanel = (props) => {
  return (
    <CustomAccordion
      AccordionSummary={<Typography variant="h6">Amizades</Typography>}
      AccordionProps={{ defaultExpanded: true }}
    >
      <FriendsPicker friendsLimit={props.friendsLimit} />
    </CustomAccordion>
  );
};

export default FriendshipPanel;
