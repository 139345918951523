import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Skeleton from "@material-ui/lab/Skeleton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import logo from "../../media/logo.png";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    maxWidth: 130,
  },
  appBar: {
    backgroundColor: "dodgerblue",
  },
  imageLink: {
    display: "flex",
    alignItems: "center",
  },
  userName: {
    marginLeft: "auto",
    marginRight: theme.spacing(4),
  },
}));

const NavAppBar = (props) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar} position="static">
      <Toolbar>
        <Link className={classes.imageLink} href={"/?dealId=" + props.dealId}>
          <img src={logo} alt="logo" className={classes.logo} />
        </Link>
        {props.user ? (
          <React.Fragment>
            <Typography className={classes.userName} variant="h6">
              {`Olá, ${[props.user.NAME, props.user.LAST_NAME].join(" ")}`}
            </Typography>
            <Avatar
              className={classes.avatar}
              src={props.user.PERSONAL_PHOTO}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Skeleton className={classes.userName} style={{ paddingLeft: 250 }}>
              <Typography variant="h6">.</Typography>
            </Skeleton>
            <Skeleton className={classes.avatar} variant="circle">
              <Avatar />
            </Skeleton>
          </React.Fragment>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavAppBar;
