import React from "react";

import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

const TransferPaymentInputCollection = (props) => {
  const transferPaymentData = props.storedPaymentData
    .filter(
      (paymentInformation) =>
        Object.values(paymentInformation.PROPERTY_450)[0] === "350"
    )
    .slice(-1)[0];

  return (
    <Box marginY={1} width="100%">
      <Grid container spacing={2}>
        <Grid item xs sm md lg xl>
          <FormControl fullWidth>
            <InputLabel>Banco</InputLabel>
            <Select
              name="bank"
              defaultValue={
                transferPaymentData
                  ? transferPaymentData.PROPERTY_460
                    ? Object.values(transferPaymentData.PROPERTY_460)[0]
                    : ""
                  : ""
              }
            >
              {Object.entries(props.bankBrands).map((bankBrand, index) => (
                <MenuItem key={index} value={bankBrand[0]}>
                  {bankBrand[1]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs sm md lg xl>
          <FormControl fullWidth>
            <TextField
              name="ownerFullName"
              label="Nome do titular"
              helperText="Nome completo do titular"
              defaultValue={
                transferPaymentData
                  ? transferPaymentData.PROPERTY_494
                    ? Object.values(transferPaymentData.PROPERTY_494)[0]
                    : ""
                  : ""
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs sm md lg xl>
          <FormControl fullWidth>
            <TextField
              name="cpf"
              label="CPF do titular"
              defaultValue={
                transferPaymentData
                  ? transferPaymentData.PROPERTY_452
                    ? Object.values(transferPaymentData.PROPERTY_452)[0]
                    : ""
                  : ""
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs sm md lg xl>
          <FormControl fullWidth>
            <TextField
              name="branchCode"
              label="Agência"
              helperText="Número da agência"
              defaultValue={
                transferPaymentData
                  ? transferPaymentData.PROPERTY_462
                    ? Object.values(transferPaymentData.PROPERTY_462)[0]
                    : ""
                  : ""
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs sm md lg xl>
          <FormControl fullWidth>
            <TextField
              name="bankAccount"
              label="Conta"
              helperText="Número da conta"
              defaultValue={
                transferPaymentData
                  ? transferPaymentData.PROPERTY_464
                    ? Object.values(transferPaymentData.PROPERTY_464)[0]
                    : ""
                  : ""
              }
            />
          </FormControl>
        </Grid>
        {props.children}
      </Grid>
    </Box>
  );
};

export default TransferPaymentInputCollection;
