import React from "react";

import TextField from "@material-ui/core/TextField";

import FriendsPickerDisplay from "../display/FriendsPickerDisplay";

const FriendsPicker = (props) => {
  const [options, setOptions] = React.useState([]);
  const [selectedCount, setSelectedCount] = React.useState(0);
  const [value, setValue] = React.useState([]);

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      window.BX24.callMethod(
        "crm.contact.list",
        {
          filter: { "%NAME": event.target.value },
          select: ["ID", "NAME", "LAST_NAME"],
        },
        (response) => setOptions(response.data())
      );
    }
  };

  return (
    <React.Fragment>
      <FriendsPickerDisplay
        options={options}
        getOptionLabel={(option) => [option.NAME, option.LAST_NAME].join(" ")}
        getOptionDisabled={(_options) => selectedCount >= props.friendsLimit}
        fullWidth
        multiple
        renderInput={(params) => <TextField {...params} label="Amigo(a)" />}
        noOptionsText="Amigo(a) não encontrado"
        onKeyUp={handleKeyUp}
        value={value}
        onChange={(_event, selected) => {
          setValue(selected);
          setSelectedCount(selected.length);
        }}
      />
      <TextField
        name="friends"
        value={value.map((obj) => obj.ID).join(",")}
        type="hidden"
      />
    </React.Fragment>
  );
};

export default FriendsPicker;
