import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

import CustomAccordion from "./CustomAccordion";

const useStyles = makeStyles(() => ({
  voucher: {
    color: "red",
    fontWeight: 500,
  },
}));

const RegistrationTotalPanel = (props) => {
  const classes = useStyles();

  return props.event ? (
    <CustomAccordion
      AccordionSummary={
        <div>
          <Typography variant="subtitle1">
            {`Valor Pacote: ${new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: props.currency,
            }).format(
              props.currencyPrice
                ? props.accommodationTotal * props.currencyPrice
                : props.accommodationTotal
            )}`}
          </Typography>
          <Typography variant="subtitle1" className={classes.voucher}>
            {`Voucher/Bônus: ${
              props.discountTotal > 0 ? "- " : ""
            }${new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: props.currency,
            }).format(
              props.currencyPrice
                ? props.discountTotal * props.currencyPrice
                : props.discountTotal
            )}`}
          </Typography>
          <Typography variant="h6">
            {`Total: ${new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: props.currency,
            }).format(
              props.currencyPrice
                ? props.registrationTotal * props.currencyPrice
                : props.registrationTotal
            )}`}
          </Typography>
        </div>
      }
      AccordionProps={{ defaultExpanded: true }}
    />
  ) : (
    <CustomAccordion disabled />
  );
};

export default RegistrationTotalPanel;
