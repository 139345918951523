import React from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

const Panel = (props) => {
  return (
    <Box p={3}>
      <Container maxWidth='md'>{props.children}</Container>
    </Box>
  );
};

export default Panel;
