import React from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  tag: {
    margin: 4,
  },
  input: {
    padding: "6px 0 7px !important",
  },
}));

const FriendsPickerDisplay = (props) => {
  const classes = useStyles();
  return (
    <Autocomplete
      classes={{ tag: classes.tag, inputRoot: classes.input }}
      {...props}
    />
  );
};

export default FriendsPickerDisplay;
