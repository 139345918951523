import React from "react";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CSSBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { parse, addHours, startOfMonth, format } from "date-fns";

import AccomodationPanel from "./display/AccommodationPanel";
import ActionFloaters from "./display/ActionFloaters";
import ContactPanel from "./display/ContactPanel";
import CouponsPanel from "./display/CouponsPanel";
import CoursePanel from "./display/CoursePanel";
import CustomAccordion from "./display/CustomAccordion";
import DollarPanel from "./display/DollarPanel";
import EventPanel from "./display/EventPanel";
import FriendshipPanel from "./display/FriendshipPanel";
import NavAppBar from "./display/NavAppBar";
import ObservationsPanel from "./display/ObservationsPanel";
import Panel from "./display/Panel";
import PaymentPanel from "./display/PaymentPanel";
import ReferralForm from "./display/ReferralForm";
import RegistrationTotalPanel from "./display/RegistrationTotalPanel";
import SpecialNeedsPanel from "./display/SpecialNeedsPanel";
import TransportationPanel from "./container/TransportationPanel";
import VIPBalancePanel from "./display/VIPBalancePanel";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const App = () => {
  const [appData, setAppData] = React.useState({});
  const [registrationTotal, setRegistrationTotal] = React.useState("0");
  const [accommodationTotal, setAccommodationTotal] = React.useState("0");
  const [discountTotal, setDiscountTotal] = React.useState("0");
  const [currencyPrice, setCurrencyPrice] = React.useState(0);
  const [friendsLimit, setFriendsLimit] = React.useState(0);
  const [specialBooking, setSpecialBooking] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [invited, setInvited] = React.useState(false);
  const [invitationList, setInvitationList] = React.useState([]);

  const classes = useStyles();
  const dealId = new URLSearchParams(window.location.search).get("dealId");

  React.useEffect(() => {
    if (window.BX24) {
      window.BX24.callBatch(
        {
          user: ["user.current", {}],
          contactFields: ["crm.contact.fields", {}],
          registration: ["crm.deal.get", { id: dealId }],
          contact: [
            "crm.contact.get",
            { id: "$result[registration][CONTACT_ID]" },
          ],
          event: [
            "crm.deal.get",
            { id: "$result[registration][UF_CRM_1597947940]" },
          ],
          meetingPointsFields: [
            "lists.field.get",
            {
              IBLOCK_TYPE_ID: "lists",
              IBLOCK_ID: 68,
            },
          ],
          meetingPoints: [
            "lists.element.get",
            {
              IBLOCK_TYPE_ID: "lists",
              IBLOCK_ID: 68,
              FILTER: {
                PROPERTY_526: "$result[event][ID]",
              },
            },
          ],
          courses: [
            "crm.deal.list",
            {
              filter: {
                CATEGORY_ID: 4,
                UF_CRM_1597947940: "$result[registration][UF_CRM_1597947940]",
              },
              select: ["*", "UF_*"],
            },
          ],
          hotels: [
            "crm.company.list",
            { filter: { ID: "$result[event][UF_CRM_1602808210]" } },
          ],
          dealFields: ["crm.deal.fields", {}],
          packageItems: [
            "lists.element.get",
            {
              IBLOCK_TYPE_ID: "lists",
              IBLOCK_ID: 54,
              FILTER: {
                PROPERTY_386: "$result[event][ID]",
              },
            },
          ],
          storedPaymentData: [
            "lists.element.get",
            {
              IBLOCK_TYPE_ID: "lists",
              IBLOCK_ID: 62,
              FILTER: {
                PROPERTY_448: "$result[contact][ID]",
              },
            },
          ],
          coupons: [
            "lists.element.get",
            {
              IBLOCK_TYPE_ID: "lists",
              IBLOCK_ID: 66,
              FILTER: {
                PROPERTY_614: "$result[event][ID]",
              },
            },
          ],
          couponFields: [
            "lists.field.get",
            { IBLOCK_TYPE_ID: "lists", IBLOCK_ID: 66 },
          ],
          transportationFields: [
            "lists.field.get",
            { IBLOCK_TYPE_ID: "lists", IBLOCK_ID: 48 },
          ],
          packageItemFields: [
            "lists.field.get",
            { IBLOCK_TYPE_ID: "lists", IBLOCK_ID: 54 },
          ],
          requestedAccommodationFields: [
            "lists.field.get",
            { IBLOCK_TYPE_ID: "lists", IBLOCK_ID: 46 },
          ],
          paymentInformationFields: [
            "lists.field.get",
            { IBLOCK_TYPE_ID: "lists", IBLOCK_ID: 62 },
          ],
        },
        (response) => {
          setAppData(
            Object.entries(response).reduce((previousValue, currentValue) => {
              return {
                ...previousValue,
                [currentValue[0]]: currentValue[1].data(),
              };
            }, {})
          );
        }
      );
    }
  }, [dealId]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { submitter } = event.nativeEvent;
    const {
      accommodationSelectedTotal,
      selectedPackage,
      dollarPrice,
      friends,
      fullName,
      phone,
      email,
      checkIn,
      checkOut,
      specialNeeds,
      selectedCourses,
      observations,
      transportationFields,
      coupon,
      entryPaymentFields,
      balancePaymentFields,
    } = event.nativeEvent.target;

    let discountType;
    let discountCoupon;
    let registrationTotal;
    let coupons = [{}];

    if (coupon?.length) {
      coupons = Array.from(coupon);
    } else if (coupon) {
      coupons = [coupon];
    }

    const selectedCoupon = coupons.filter((coupon) => coupon.checked)[0];

    if (selectedCoupon) {
      discountCoupon = appData.coupons.filter(
        (discountCoupon) => discountCoupon.ID === selectedCoupon.value
      )[0];
      discountType = Object.values(discountCoupon.PROPERTY_544)[0];
    }

    if (discountType === "490") {
      registrationTotal =
        Number(accommodationSelectedTotal.value) -
        Number(Object.values(discountCoupon.PROPERTY_476)[0]);
      setDiscountTotal(Number(Object.values(discountCoupon.PROPERTY_476)[0]));
    } else if (discountType === "488") {
      registrationTotal = -(
        Number(accommodationSelectedTotal.value) *
        (Number(Object.values(discountCoupon.PROPERTY_476)[0]) / 100 - 1)
      );
      setDiscountTotal(
        Number(accommodationSelectedTotal.value) *
          (Number(Object.values(discountCoupon.PROPERTY_476)[0]) / 100)
      );
    } else {
      registrationTotal = Number(accommodationSelectedTotal.value);
      setDiscountTotal("0");
    }

    if (submitter.name === "calculateTotal") {
      setRegistrationTotal(registrationTotal);
      setAccommodationTotal(Number(accommodationSelectedTotal.value));

      if (dollarPrice) setCurrencyPrice(Number(dollarPrice.value));
    } else if (submitter.name === "referralInvitation") {
      setOpen(!open);

      if (fullName.value && (phone.value || email.value)) {
        window.BX24.callBatch(
          {
            contact: [
              "crm.contact.add",
              {
                fields: {
                  NAME: fullName.value.split(" ")[0],
                  LAST_NAME: fullName.value.split(" ").slice(1).join(" "),
                  PHONE: [
                    {
                      VALUE: phone.value,
                      VALUE_TYPE: "MOBILE",
                    },
                  ],
                  EMAIL: [
                    {
                      VALUE: email.value,
                      VALUE_TYPE: "OTHER",
                    },
                  ],
                  SOURCE_ID: "RECOMMENDATION",
                  TYPE_ID: "CLIENT",
                },
              },
            ],
            deal: [
              "crm.deal.add",
              {
                fields: {
                  CONTACT_ID: "$result[contact][CONTACT_ID]",
                  UF_CRM_1597947940: appData.event.ID,
                  UF_CRM_1608727276: appData.contact.ID,
                  ASSIGNED_BY_ID: appData.user.ID,
                  CATEGORY_ID: 6,
                  SOURCE_ID: "RECOMMENDATION",
                },
              },
            ],
          },
          () => {
            setOpen(false);
            setInvited(true);
            setInvitationList((previousState) => {
              return [
                ...previousState,
                {
                  fullName: fullName.value,
                  phone: phone.value,
                  email: email.value,
                },
              ];
            });
          }
        );
      }
    } else if (submitter.name === "submitRegistration") {
      setOpen(!open);

      const packageBought = appData.packageItems.filter(
        (packageItem) => packageItem.ID === selectedPackage.value
      )[0];

      const roomType = packageBought.PROPERTY_474
        ? Object.entries(
            appData.requestedAccommodationFields.PROPERTY_324
              .DISPLAY_VALUES_FORM
          ).filter(
            (array) =>
              array[1] ===
              appData.packageItemFields.PROPERTY_474.DISPLAY_VALUES_FORM[
                Object.values(packageBought.PROPERTY_474)[0]
              ]
          )[0][0]
        : "";

      const checkInDate = `${checkIn.value
        .split(" ")[0]
        .split("/")
        .reverse()
        .join("-")}T${checkIn.value.split(" ")[1]}`;

      const checkOutDate = `${checkOut.value
        .split(" ")[0]
        .split("/")
        .reverse()
        .join("-")}T${checkOut.value.split(" ")[1]}`;

      const coursesBought = selectedCourses.value.split(",").reduce(
        (previousValue, courseId, index) => ({
          ...previousValue,
          ["course" + index]: [
            "lists.element.add",
            {
              IBLOCK_TYPE_ID: "lists",
              IBLOCK_ID: 44,
              ELEMENT_ID: Date.now(),
              fields: {
                NAME: [appData.contact.NAME, appData.contact.LAST_NAME].join(
                  " "
                ),
                PROPERTY_308: appData.contact.ID,
                PROPERTY_310: courseId,
                PROPERTY_312: appData.event.ID,
                PROPERTY_314: dealId,
              },
            },
          ],
        }),
        {}
      );

      const coursesUpdated = selectedCourses.value
        ? selectedCourses.value.split(",").reduce(
            (previousValue, courseId, index) => ({
              ...previousValue,
              ["courseUpdate" + index]: [
                "crm.deal.update",
                {
                  id: courseId,
                  fields: {
                    UF_CRM_1612811807:
                      Number(
                        appData.courses.filter(
                          (course) => course.ID === courseId
                        )[0].UF_CRM_1612811807
                      ) - 1,
                  },
                },
              ],
            }),
            {}
          )
        : {};

      let transportation;

      if (transportationFields.length) {
        transportation = Array.from(transportationFields);
      } else {
        transportation = [transportationFields];
      }

      const transportationDoc = transportation.map((fieldset) => {
        return {
          transportType: fieldset.elements.transportType.value,
          meetingPoint: fieldset.elements.meetingPoint.value
            ? appData.meetingPointsFields.PROPERTY_644.DISPLAY_VALUES_FORM[
                fieldset.elements.meetingPoint.value
              ]
            : "",
        };
      });

      transportation = transportation.reduce(
        (previousValue, fieldset, index) => {
          return {
            ...previousValue,
            [`transportation${++index}`]: [
              "lists.element.add",
              {
                IBLOCK_TYPE_ID: "lists",
                IBLOCK_ID: 48,
                ELEMENT_ID: Date.now(),
                fields: {
                  NAME: [appData.contact.NAME, appData.contact.LAST_NAME].join(
                    " "
                  ),
                  PROPERTY_352: appData.contact.ID,
                  PROPERTY_350: appData.event.ID,
                  PROPERTY_348: dealId,
                  PROPERTY_528: fieldset.elements.transportType.value,
                  PROPERTY_530: fieldset.elements.transferType.value,
                  PROPERTY_534: fieldset.elements.meetingPoint.value,
                  PROPERTY_532: fieldset.elements.vehicle.value,
                  PROPERTY_536: fieldset.elements.arrivalDate.value
                    ? addHours(
                        parse(
                          fieldset.elements.arrivalDate.value,
                          "dd/MM/yyyy HH:mm",
                          new Date()
                        ),
                        -6
                      ).toISOString()
                    : "",
                  PROPERTY_538: fieldset.elements.ticketCode.value,
                  PROPERTY_550: fieldset.elements.especialTranport.value,
                  PROPERTY_552: fieldset.elements.especialObs.value,
                },
              },
            ],
          };
        },
        {}
      );

      let balance = balancePaymentFields.length
        ? Array.from(balancePaymentFields)
        : [balancePaymentFields];
      let entry = entryPaymentFields.length
        ? Array.from(entryPaymentFields)
        : [entryPaymentFields];

      entry = entry.reduce((previousValues, fieldset, index) => {
        let entry = { ...previousValues };
        if (fieldset.elements.entryPaymentAmount.value) {
          if (fieldset.elements.entryPaymentMethod.value === "vip") {
            entry = {
              ...entry,
              [`entry${index}`]: [
                "lists.element.add",
                {
                  IBLOCK_TYPE_ID: "lists",
                  IBLOCK_ID: 58,
                  ELEMENT_ID: Date.now(),
                  fields: {
                    NAME: [
                      appData.contact.NAME,
                      appData.contact.LAST_NAME,
                    ].join(" "),
                    PROPERTY_404: appData.contact.ID,
                    PROPERTY_406: dealId,
                    PROPERTY_410: addHours(new Date(), -6).toISOString(),
                    PROPERTY_412: fieldset.elements.entryPaymentAmount.value,
                    PROPERTY_414: "238",
                  },
                },
              ],
            };
          } else {
            entry = {
              ...entry,
              [`entry${index}`]: [
                "lists.element.add",
                {
                  IBLOCK_TYPE_ID: "lists",
                  IBLOCK_ID: 62,
                  ELEMENT_ID: Date.now(),
                  fields: {
                    NAME: [
                      appData.contact.NAME,
                      appData.contact.LAST_NAME,
                    ].join(" "),
                    PROPERTY_486: dealId,
                    PROPERTY_488: fieldset.elements.entryPaymentAmount.value,
                    PROPERTY_450: fieldset.elements.entryPaymentMethod.value,
                    PROPERTY_490: 370,
                    PROPERTY_448: appData.contact.ID,
                    PROPERTY_518: fieldset.elements.installments.value,
                    PROPERTY_540: fieldset.elements.paymentDate.value
                      ? addHours(
                          parse(
                            fieldset.elements.paymentDate.value,
                            "dd/MM/yyyy",
                            new Date()
                          ),
                          -6
                        ).toISOString()
                      : "",
                  },
                },
              ],
            };

            if (
              fieldset.elements.entryPaymentMethod.value === "348" ||
              fieldset.elements.entryPaymentMethod.value === "436"
            ) {
              entry[`entry${index}`][1].fields = {
                ...entry[`entry${index}`][1].fields,
                PROPERTY_452: fieldset.elements.cpf.value,
                PROPERTY_454: fieldset.elements.creditCardNumber.value,
                PROPERTY_456: fieldset.elements.expireDate.value
                  ? startOfMonth(
                      parse(
                        fieldset.elements.expireDate.value,
                        "MM/yyyy",
                        new Date()
                      )
                    ).toISOString()
                  : "",
                PROPERTY_492: fieldset.elements.creditCardBrand.value,
                PROPERTY_458: fieldset.elements.cvv.value,
                PROPERTY_494: fieldset.elements.creditCardName.value,
              };
            } else if (fieldset.elements.entryPaymentMethod.value === "350") {
              entry[`entry${index}`][1].fields = {
                ...entry[`entry${index}`][1].fields,
                PROPERTY_452: fieldset.elements.cpf.value,
                PROPERTY_494: fieldset.elements.ownerFullName.value,
                PROPERTY_460: fieldset.elements.bank.value,
                PROPERTY_462: fieldset.elements.branchCode.value,
                PROPERTY_464: fieldset.elements.bankAccount.value,
              };
            } else if (fieldset.elements.entryPaymentMethod.value === "434") {
              entry[`entry${index}`][1].fields = {
                ...entry[`entry${index}`][1].fields,
                PROPERTY_452: fieldset.elements.cpf.value,
                PROPERTY_494: fieldset.elements.ownerFullName.value,
                PROPERTY_460: fieldset.elements.bank.value,
                PROPERTY_462: fieldset.elements.branchCode.value,
                PROPERTY_464: fieldset.elements.bankAccount.value,
                PROPERTY_520: fieldset.elements.checkNumber.value,
              };
            }
          }
        }

        return entry;
      }, {});

      balance = balance.reduce((previousValues, fieldset, index) => {
        let balance = { ...previousValues };

        if (fieldset.elements.balancePaymentAmount.value) {
          if (fieldset.elements.balancePaymentMethod.value === "vip") {
            balance = {
              ...balance,
              [`balance${index}`]: [
                "lists.element.add",
                {
                  IBLOCK_TYPE_ID: "lists",
                  IBLOCK_ID: 58,
                  ELEMENT_ID: Date.now(),
                  fields: {
                    NAME: [
                      appData.contact.NAME,
                      appData.contact.LAST_NAME,
                    ].join(" "),
                    PROPERTY_404: appData.contact.ID,
                    PROPERTY_406: dealId,
                    PROPERTY_410: addHours(new Date(), -6).toISOString(),
                    PROPERTY_412: fieldset.elements.balancePaymentAmount.value,
                    PROPERTY_414: "238",
                  },
                },
              ],
            };
          } else {
            balance = {
              ...balance,
              [`balance${index}`]: [
                "lists.element.add",
                {
                  IBLOCK_TYPE_ID: "lists",
                  IBLOCK_ID: 62,
                  ELEMENT_ID: Date.now(),
                  fields: {
                    NAME: [
                      appData.contact.NAME,
                      appData.contact.LAST_NAME,
                    ].join(" "),
                    PROPERTY_486: dealId,
                    PROPERTY_488: fieldset.elements.balancePaymentAmount.value,
                    PROPERTY_450: fieldset.elements.balancePaymentMethod.value,
                    PROPERTY_490: 370,
                    PROPERTY_448: appData.contact.ID,
                    PROPERTY_518: fieldset.elements.installments.value,
                    PROPERTY_540: fieldset.elements.paymentDate.value
                      ? addHours(
                          parse(
                            fieldset.elements.paymentDate.value,
                            "dd/MM/yyyy",
                            new Date()
                          ),
                          -6
                        ).toISOString()
                      : "",
                  },
                },
              ],
            };

            if (
              fieldset.elements.balancePaymentMethod.value === "348" ||
              fieldset.elements.balancePaymentMethod.value === "436"
            ) {
              balance[`balance${index}`][1].fields = {
                ...balance[`balance${index}`][1].fields,
                PROPERTY_452: fieldset.elements.cpf.value,
                PROPERTY_454: fieldset.elements.creditCardNumber.value,
                PROPERTY_456: fieldset.elements.expireDate.value
                  ? startOfMonth(
                      parse(
                        fieldset.elements.expireDate.value,
                        "MM/yyyy",
                        new Date()
                      )
                    ).toISOString()
                  : "",
                PROPERTY_492: fieldset.elements.creditCardBrand.value,
                PROPERTY_458: fieldset.elements.cvv.value,
                PROPERTY_494: fieldset.elements.creditCardName.value,
              };
            } else if (fieldset.elements.balancePaymentMethod.value === "350") {
              balance[`balance${index}`][1].fields = {
                ...balance[`balance${index}`][1].fields,
                PROPERTY_452: fieldset.elements.cpf.value,
                PROPERTY_494: fieldset.elements.ownerFullName.value,
                PROPERTY_460: fieldset.elements.bank.value,
                PROPERTY_462: fieldset.elements.branchCode.value,
                PROPERTY_464: fieldset.elements.bankAccount.value,
              };
            } else if (fieldset.elements.balancePaymentMethod.value === "434") {
              balance[`balance${index}`][1].fields = {
                ...balance[`balance${index}`][1].fields,
                PROPERTY_452: fieldset.elements.cpf.value,
                PROPERTY_494: fieldset.elements.ownerFullName.value,
                PROPERTY_460: fieldset.elements.bank.value,
                PROPERTY_462: fieldset.elements.branchCode.value,
                PROPERTY_464: fieldset.elements.bankAccount.value,
                PROPERTY_520: fieldset.elements.checkNumber.value,
              };
            }
          }
        }

        return balance;
      }, {});

      const registrationTotalPaid = dollarPrice
        ? Number(accommodationSelectedTotal.value) * Number(dollarPrice)
        : Number(accommodationSelectedTotal.value);

      window.BX24.callBatch(
        {
          ...coursesUpdated,
          ...transportation,
          subscriptionDoc: [
            "crm.documentgenerator.document.add",
            {
              templateId: 12,
              entityTypeId: 2,
              entityId: dealId,
              values: {
                nomeInscricao: [
                  appData.contact.NAME,
                  appData.contact.LAST_NAME,
                ].join(" "),
                dataEvento: appData.event.UF_CRM_1523902149
                  ? format(
                      new Date(appData.event.UF_CRM_1523902149),
                      "dd/MM/yyyy HH:mm"
                    )
                  : "",
                nomePacote: packageBought.NAME,
                nomeCursos: appData.courses
                  .filter((course) =>
                    selectedCourses.value.split(",").includes(course.ID)
                  )
                  .map((course) => course.TITLE)
                  .join(", "),
                encontroIda: transportationDoc
                  .filter((transport) => transport.transportType === "458")
                  .map((transport) => transport.meetingPoint)
                  .join(", "),
                encontroVolta: transportationDoc
                  .filter((transport) => transport.transportType === "460")
                  .map((transport) => transport.meetingPoint)
                  .join(", "),
                valorTotalPacote: new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: appData.event.CURRENCY_ID,
                }).format(registrationTotal),
                observacoes: observations,
              },
            },
          ],
          enablePublicURLDocument: [
            "crm.documentgenerator.document.enablepublicurl",
            { id: "$result[subscriptionDoc][document][id]", status: 1 },
          ],
          accommodation: [
            "lists.element.add",
            {
              IBLOCK_TYPE_ID: "lists",
              IBLOCK_ID: 46,
              ELEMENT_ID: Date.now(),
              fields: {
                NAME: [appData.contact.NAME, appData.contact.LAST_NAME].join(
                  " "
                ),
                PROPERTY_320: Object.values(packageBought.PROPERTY_384)[0],
                PROPERTY_324: roomType,
                PROPERTY_326: checkInDate,
                PROPERTY_328: checkOutDate,
                PROPERTY_336: appData.contact.ID,
                PROPERTY_334: dealId,
                PROPERTY_338: appData.event.ID,
                PROPERTY_514: packageBought.ID,
              },
            },
          ],
          couponUse: [
            "lists.element.add",
            {
              IBLOCK_TYPE_ID: "lists",
              IBLOCK_ID: 78,
              ELEMENT_ID: Date.now(),
              fields: {
                NAME: [appData.contact.NAME, appData.contact.LAST_NAME].join(
                  " "
                ),
                PROPERTY_616: dealId,
                PROPERTY_618: appData.contact.ID,
                PROPERTY_620: appData.event.ID,
              },
            },
          ],
          contact: [
            "crm.contact.update",
            {
              id: appData.contact.ID,
              fields: {
                UF_CRM_1606309318: specialNeeds.value.split(","),
                ASSIGNED_BY_ID: appData.user.ID,
              },
            },
          ],
          ...coursesBought,
          registration: [
            "crm.deal.update",
            {
              id: dealId,
              fields: {
                COMMENTS: observations,
                OPPORTUNITY: registrationTotalPaid,
                STAGE_ID: "PREPAYMENT_INVOICE",
              },
            },
          ],
          ...entry,
          ...balance,
          accommodationFriends: friends.value
            ? [
                "lists.element.add",
                {
                  IBLOCK_TYPE_ID: "lists",
                  IBLOCK_ID: 64,
                  ELEMENT_ID: Date.now(),
                  fields: {
                    NAME: [
                      appData.contact.NAME,
                      appData.contact.LAST_NAME,
                    ].join(" "),
                    PROPERTY_470: appData.contact.ID,
                    PROPERTY_472: friends.value.split(","),
                    PROPERTY_480: dealId,
                  },
                },
              ]
            : [],
          packageQuantityUpdate: [
            "lists.element.update",
            {
              IBLOCK_TYPE_ID: "lists",
              IBLOCK_ID: 54,
              ELEMENT_ID: packageBought.ID,
              FIELDS: {
                ...packageBought,
                PROPERTY_392:
                  Number(Object.values(packageBought.PROPERTY_392)[0]) - 1,
              },
            },
          ],
          couponUpdate: [
            "lists.element.update",
            discountCoupon
              ? {
                  IBLOCK_TYPE_ID: "lists",
                  IBLOCK_ID: 66,
                  ELEMENT_ID: discountCoupon.ID,
                  FIELDS: {
                    ...discountCoupon,
                    PROPERTY_548: "496",
                    PROPERTY_478: discountCoupon.PROPERTY_478
                      ? Object.values(discountCoupon.PROPERTY_478)[0]
                          .split("/")
                          .reverse()
                          .join("-")
                      : "",
                    PROPERTY_484: dealId,
                  },
                }
              : {},
          ],
        },
        () => {
          setOpen(false);
          setSubmitted(true);
        }
      );
    }
  };

  return (
    <React.Fragment>
      <CSSBaseline />
      <NavAppBar user={appData.user} dealId={dealId} />
      <Panel>
        {submitted ? (
          <CustomAccordion
            AccordionSummary={
              <Typography variant="h6">Inscrição realizada</Typography>
            }
            AccordionProps={{ defaultExpanded: true }}
          />
        ) : (
          <React.Fragment>
            <ContactPanel contact={appData.contact} />
            <EventPanel
              meetingPoints={appData.meetingPoints}
              meetingPointsFields={appData.meetingPointsFields}
              event={appData.event}
              hotels={appData.hotels}
            />
            <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
              <AccomodationPanel
                packageItemFields={appData.packageItemFields}
                packageItems={appData.packageItems}
                hotels={appData.hotels}
                event={appData.event}
                setFriendsLimit={setFriendsLimit}
                setSpecialBooking={setSpecialBooking}
                specialBooking={specialBooking}
              />
              <FriendshipPanel friendsLimit={friendsLimit} />
              <SpecialNeedsPanel
                contact={appData.contact}
                contactFields={appData.contactFields}
              />
              <TransportationPanel
                meetingPoints={appData.meetingPoints}
                meetingPointsFields={appData.meetingPointsFields}
                transportationFields={appData.transportationFields}
                specialBooking={specialBooking}
                event={appData.event}
                dealFields={appData.dealFields}
              />
              <CoursePanel courses={appData.courses} event={appData.event} />
              {appData.event ? (
                appData.event.CURRENCY_ID === "USD" ? (
                  <React.Fragment>
                    <DollarPanel />
                    <RegistrationTotalPanel
                      event={appData.event}
                      registrationTotal={registrationTotal}
                      currency="USD"
                      accommodationTotal={accommodationTotal}
                      discountTotal={discountTotal}
                    />
                  </React.Fragment>
                ) : undefined
              ) : (
                ""
              )}
              <CouponsPanel
                coupons={appData.coupons}
                couponFields={appData.couponFields}
              />
              <RegistrationTotalPanel
                event={appData.event}
                registrationTotal={registrationTotal}
                currency="BRL"
                currencyPrice={currencyPrice}
                accommodationTotal={accommodationTotal}
                discountTotal={discountTotal}
              />
              <VIPBalancePanel contact={appData.contact} />
              <PaymentPanel
                event={appData.event}
                paymentInformationFields={appData.paymentInformationFields}
                storedPaymentData={appData.storedPaymentData}
                registrationTotal={registrationTotal}
                contact={appData.contact}
              />
              <ReferralForm
                invited={invited}
                setInvited={setInvited}
                invitationList={invitationList}
              />
              <ObservationsPanel />
              <Backdrop open={open} className={classes.backdrop}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <ActionFloaters />
            </form>
          </React.Fragment>
        )}
      </Panel>
    </React.Fragment>
  );
};

export default App;
