import React from "react";

import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import format from "date-fns/format";
import ptBrLocale from "date-fns/locale/pt-BR";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import CustomAccordion from "./CustomAccordion";

const EventPanel = (props) => {
  const meetingPoints =
    Object.prototype.toString.call(props.meetingPoints) === "[object Object]"
      ? [props.meetingPoints]
      : props.meetingPoints;

  return props.event &&
    meetingPoints &&
    props.meetingPointsFields &&
    props.hotels ? (
    <CustomAccordion
      AccordionSummary={<Typography variant="h6">Evento</Typography>}
      AccordionProps={{ defaultExpanded: true }}
    >
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell variant="head" component="th">
                Nome
              </TableCell>
              <TableCell>{props.event.TITLE}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" component="th">
                Hotéis
              </TableCell>
              <TableCell>
                {props.hotels
                  .filter((hotel) =>
                    props.event.UF_CRM_1602808210.includes(hotel.ID)
                  )
                  .map((hotel) => hotel.TITLE)
                  .join(", ")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" component="th">
                Noites
              </TableCell>
              <TableCell>
                {props.event.UF_CRM_1523902250 && props.event.UF_CRM_1523902149
                  ? differenceInCalendarDays(
                      new Date(props.event.UF_CRM_1523902250),
                      new Date(props.event.UF_CRM_1523902149)
                    )
                  : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" component="th">
                Dias da semana
              </TableCell>
              <TableCell>
                {props.event.UF_CRM_1523902250 && props.event.UF_CRM_1523902149
                  ? eachDayOfInterval({
                      start: new Date(props.event.UF_CRM_1523902149),
                      end: new Date(props.event.UF_CRM_1523902250),
                    })
                      .map((dateObject) => {
                        let weekDay = format(dateObject, "EEEE", {
                          locale: ptBrLocale,
                        });

                        weekDay =
                          weekDay.charAt(0).toUpperCase() + weekDay.slice(1);

                        return weekDay;
                      })
                      .join(", ")
                  : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" component="th">
                Início
              </TableCell>
              <TableCell>
                {props.event.UF_CRM_1523902149
                  ? format(
                      new Date(props.event.UF_CRM_1523902149),
                      "dd/MM/yyyy HH:mm"
                    )
                  : "Início não informado"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" component="th">
                Término
              </TableCell>
              <TableCell>
                {props.event.UF_CRM_1523902250
                  ? format(
                      new Date(props.event.UF_CRM_1523902250),
                      "dd/MM/yyyy HH:mm"
                    )
                  : "Término não informado"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" component="th">
                Limite de cursos por pessoa
              </TableCell>
              <TableCell>{props.event.UF_CRM_1603322516}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </CustomAccordion>
  ) : (
    <CustomAccordion disabled />
  );
};

export default EventPanel;
