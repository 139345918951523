import React from "react";

import { KeyboardDatePicker } from "@material-ui/pickers/";

const KeyboardDatePickerStateful = (props) => {
  const [inputValue, setInputValue] = React.useState(props.state);

  return (
    <KeyboardDatePicker
      {...props}
      value={inputValue}
      onChange={setInputValue}
    />
  );
};

export default KeyboardDatePickerStateful;
