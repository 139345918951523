import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
  window.BX24
    ? window.BX24.init(() => console.log('Bitrix24 framework initialized.'))
    : undefined
);
