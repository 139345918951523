import React from "react";

import Typography from "@material-ui/core/Typography";

import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import makeStyles from "@material-ui/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";

import CustomAccordion from "./CustomAccordion";

const useStyles = makeStyles(() => ({
  chip: {
    margin: 4,
  },
}));

const SpecialNeedsPanel = (props) => {
  const classes = useStyles();

  return props.contactFields ? (
    <CustomAccordion
      AccordionSummary={
        <Typography variant="h6">Necessidades especiais</Typography>
      }
      AccordionProps={{ defaultExpanded: false, disabled: true }}
    >
      <FormControl fullWidth>
        <Select
          defaultValue={[]}
          name="specialNeeds"
          multiple
          renderValue={(selected) => (
            <Box display="flex" flexWrap="wrap">
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={
                    props.contactFields.UF_CRM_1606309318.items.filter(
                      (item) => item.ID === String(value)
                    )[0].VALUE
                  }
                  className={classes.chip}
                />
              ))}
            </Box>
          )}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 150,
              },
            },
            anchorOrigin: { vertical: "top", horizontal: "center" },
            transformOrigin: { vertical: "bottom", horizontal: "center" },
            getContentAnchorEl: null,
          }}
        >
          {props.contactFields.UF_CRM_1606309318.items.map((listOption) => (
            <MenuItem value={listOption.ID} key={listOption.ID}>
              {listOption.VALUE}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </CustomAccordion>
  ) : (
    <CustomAccordion disabled />
  );
};

export default SpecialNeedsPanel;
