import React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";

import CustomAccordion from "./CustomAccordion";
import SpecialBookingPanel from "../container/SpecialBookingPanel";

const useStyles = makeStyles(() => ({
  root: {
    flexWrap: "wrap",
  },
}));

const AccommodationPanel = (props) => {
  const classes = useStyles();
  const packageItems =
    Object.prototype.toString.call(props.packageItems) === "[object Object]"
      ? [props.packageItems]
      : props.packageItems;

  const hotels =
    Object.prototype.toString.call(props.hotels) === "[object Object]"
      ? [props.hotels]
      : props.hotels;

  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    const value = event.target.value;
    const packageItem = props.packageItems.filter(
      (packageItem) => packageItem.ID === value
    )[0];

    const roomType = packageItem.PROPERTY_474
      ? props.packageItemFields.PROPERTY_474.DISPLAY_VALUES_FORM[
          Object.values(packageItem.PROPERTY_474)[0]
        ]
      : undefined;

    setValue(value);
    switch (roomType) {
      case "Duplo":
        props.setFriendsLimit(1);
        break;
      case "Triplo":
        props.setFriendsLimit(2);
        break;
      case "Quádruplo":
        props.setFriendsLimit(3);
        break;
      case "Quíntuplo":
        props.setFriendsLimit(4);
        break;
      default:
        props.setFriendsLimit(0);
        break;
    }
  };

  return packageItems &&
    props.packageItemFields &&
    hotels.length &&
    props.event ? (
    <CustomAccordion
      AccordionSummary={<Typography variant="h6">Hospedagem</Typography>}
      AccordionProps={{ defaultExpanded: true }}
      AccordionDetails={{ className: classes.root }}
    >
      <RadioGroup
        name="selectedPackage"
        value={value}
        onChange={handleChange}
        row
      >
        {packageItems.map((packageItem) => (
          <FormControlLabel
            value={packageItem.ID}
            control={<Radio />}
            label={`${
              hotels.filter(
                (hotel) =>
                  hotel.ID === Object.values(packageItem.PROPERTY_384)[0]
              )[0].TITLE
            } - ${
              packageItem.PROPERTY_474
                ? props.packageItemFields.PROPERTY_474.DISPLAY_VALUES_FORM[
                    Object.values(packageItem.PROPERTY_474)[0]
                  ] + " - "
                : ""
            } ${
              props.packageItemFields.PROPERTY_512.DISPLAY_VALUES_FORM[
                Object.values(packageItem.PROPERTY_512)[0]
              ] === "Não"
                ? "Sem alimentação - "
                : ""
            } ${new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: Object.values(packageItem.PROPERTY_390)[0].split(
                "|"
              )[1],
            }).format(
              Object.values(packageItem.PROPERTY_390)[0].split("|")[0]
            )} 
              - ${Object.values(packageItem.PROPERTY_392)[0]} disponíveis
            `}
            disabled={Number(Object.values(packageItem.PROPERTY_392)[0]) <= 0}
            key={packageItem.ID}
          />
        ))}
      </RadioGroup>
      <Input
        type="hidden"
        name="accommodationSelectedTotal"
        value={
          value
            ? Object.values(
                packageItems.filter(
                  (packageItem) => packageItem.ID === value
                )[0].PROPERTY_390
              )[0].split("|")[0]
            : ""
        }
      />

      <SpecialBookingPanel
        event={props.event}
        setSpecialBooking={props.setSpecialBooking}
        specialBooking={props.specialBooking}
      />
    </CustomAccordion>
  ) : (
    <CustomAccordion disabled />
  );
};

export default AccommodationPanel;
