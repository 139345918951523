import React from "react";

import Typography from "@material-ui/core/Typography";

import CustomAccordion from "./CustomAccordion";

const ContactPanel = (props) => {
  return props.contact ? (
    <CustomAccordion
      AccordionSummary={
        <Typography variant="h6">
          {["Contato:", props.contact.NAME, props.contact.LAST_NAME].join(" ")}
        </Typography>
      }
      AccordionProps={{ defaultExpanded: true }}
    />
  ) : (
    <CustomAccordion disabled />
  );
};

export default ContactPanel;
