import React from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionActions from "@material-ui/core/AccordionActions";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(() => ({
  root: {
    cursor: "default !important",
    pointerEvents: "none",
  },
}));

const CustomAccordion = (props) => {
  const classes = useStyles();

  return props.disabled && true ? (
    <Skeleton width={"100%"}>
      <Accordion>
        <AccordionSummary>.</AccordionSummary>
      </Accordion>
    </Skeleton>
  ) : (
    <Accordion {...props.AccordionProps}>
      <AccordionSummary
        {...props.AccordionSummaryProps}
        className={classes.root}
      >
        {props.AccordionSummary}
      </AccordionSummary>
      {props.children ? (
        <AccordionDetails {...props.AccordionDetails}>
          {props.children}
        </AccordionDetails>
      ) : undefined}
      {props.AccordionActions ? (
        <React.Fragment>
          <Divider />
          <AccordionActions {...props.AccordionActionsProps}>
            {props.AccordionActions}
          </AccordionActions>
        </React.Fragment>
      ) : undefined}
    </Accordion>
  );
};

export default CustomAccordion;
