import React from "react";

import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";

import CustomAccordion from "./CustomAccordion";

const CouponsPanel = (props) => {
  const [value, setValue] = React.useState("");

  const handleClick = (event) => {
    if (event.target.value === value) {
      setValue("");
    } else {
      setValue(event.target.value);
    }
  };

  console.log(props);

  return props.coupons && props.couponFields ? (
    <CustomAccordion
      AccordionSummary={<Typography variant="h6">Voucher/Bônus</Typography>}
      AccordionProps={{ defaultExpanded: true }}
    >
      {props.coupons.filter(
        (coupon) =>
          props.couponFields.PROPERTY_548.DISPLAY_VALUES_FORM[
            Object.values(coupon.PROPERTY_548)[0]
          ] === "Sim"
      ).length ? (
        <FormControl fullWidth>
          <RadioGroup
            name="coupon"
            value={value}
            onChange={(event) => setValue(event.target.value)}
          >
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Código</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Desconto</TableCell>
                    <TableCell>Validade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.coupons
                    .filter(
                      (coupon) =>
                        props.couponFields.PROPERTY_548.DISPLAY_VALUES_FORM[
                          Object.values(coupon.PROPERTY_548)[0]
                        ] === "Sim"
                    )
                    .map((coupon) => (
                      <TableRow key={coupon.ID}>
                        <TableCell>
                          <Radio value={coupon.ID} onClick={handleClick} />
                        </TableCell>
                        <TableCell>{coupon.NAME}</TableCell>
                        <TableCell>
                          {
                            props.couponFields.PROPERTY_546.DISPLAY_VALUES_FORM[
                              Object.values(coupon.PROPERTY_546)[0]
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          {props.couponFields.PROPERTY_544.DISPLAY_VALUES_FORM[
                            Object.values(coupon.PROPERTY_544)[0]
                          ] === "%"
                            ? `${Object.values(coupon.PROPERTY_476)[0]} %`
                            : `R$ ${Object.values(coupon.PROPERTY_476)[0]}`}
                        </TableCell>
                        <TableCell>
                          {Object.values(coupon.PROPERTY_478)[0]}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </RadioGroup>
        </FormControl>
      ) : (
        "Não há descontos/vouchers disponíveis para esse cliente!"
      )}
    </CustomAccordion>
  ) : (
    <CustomAccordion disabled />
  );
};

export default CouponsPanel;
