import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import CustomAccordion from './CustomAccordion';

const ObservationsPanel = () => {
  return (
    <CustomAccordion
      AccordionSummary={<Typography variant='h6'>Observações</Typography>}
      AccordionProps={{ defaultExpanded: true }}
    >
      <FormControl fullWidth>
        <TextField
          label='Observações'
          name='observations'
          multiline
          rowsMax={3}
        />
      </FormControl>
    </CustomAccordion>
  );
};

export default ObservationsPanel;
