import React from "react";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";

import CustomAccordion from "./CustomAccordion";

const useStyles = makeStyles((theme) => ({
  root: { width: "100%" },
  divider: {
    width: "100%",
    margin: theme.spacing(2, 0, 2, 0),
    backgroundColor: "transparent",
  },
  tableRow: {
    "& > *": {
      borderBottom: "none",
    },
  },
}));

const ReferralForm = (props) => {
  const classes = useStyles();

  return props.invited ? (
    <CustomAccordion
      AccordionSummary={<Typography variant="h6">Convite realizado</Typography>}
      AccordionProps={{ defaultExpanded: true }}
      AccordionActions={
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={(event) => {
            event.preventDefault();
            props.setInvited(false);
          }}
        >
          Convidar outra
        </Button>
      }
    />
  ) : (
    <React.Fragment>
      <CustomAccordion
        AccordionSummary={
          <Typography variant="h6">Indique um interessado</Typography>
        }
        AccordionProps={{ defaultExpanded: true }}
        AccordionActions={
          <Button
            name="referralInvitation"
            type="submit"
            color="primary"
            variant="contained"
            disableElevation
          >
            Convidar
          </Button>
        }
      >
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs sm md lg xl>
              <TextField name="fullName" label="Nome completo" fullWidth />
            </Grid>
            <Grid item xs sm md lg xl>
              <TextField name="phone" label="Celular" fullWidth />
            </Grid>
            <Grid item xs sm md lg xl>
              <TextField type="email" name="email" label="E-mail" fullWidth />
            </Grid>
            <Divider classes={{ root: classes.divider }} />
            {props.invitationList.length ? (
              <TableContainer>
                <Box marginLeft={1}>
                  <Typography variant="h6">Convidados</Typography>
                </Box>
                <Table size="small">
                  <TableHead>
                    <TableRow className={classes.tableRow}>
                      <TableCell align="left" width="32.5%">
                        Nome completo
                      </TableCell>
                      <TableCell align="left" width="33.5%">
                        Celular
                      </TableCell>
                      <TableCell align="left">Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.invitationList.map((guest) => {
                      return (
                        <TableRow className={classes.tableRow}>
                          <TableCell align="left">{guest.fullName}</TableCell>
                          <TableCell align="left">{guest.phone}</TableCell>
                          <TableCell align="left">{guest.email}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : undefined}
          </Grid>
        </div>
      </CustomAccordion>
    </React.Fragment>
  );
};

export default ReferralForm;
