import React from "react";

import Typography from "@material-ui/core/Typography";

import CustomAccordion from "./CustomAccordion";

const VIPBalancePanel = (props) => {
  return props.contact ? (
    <CustomAccordion
      AccordionSummary={
        <Typography variant="h6">{`Saldo VIP: ${new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(props.contact.UF_CRM_1613582880 || 0)}`}</Typography>
      }
      AccordionProps={{ defaultExpanded: true }}
    ></CustomAccordion>
  ) : (
    <CustomAccordion disabled />
  );
};

export default VIPBalancePanel;
