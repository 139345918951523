import React from "react";

import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

import CustomAccordion from "./CustomAccordion";

const useStyles = makeStyles(() => ({
  chip: {
    margin: 4,
  },
  selected: {
    backgroundColor: "dodgerBlue !important",
  },
}));

const CoursePanel = (props) => {
  const classes = useStyles();

  const [values, setValues] = React.useState([]);

  return props.courses && props.event ? (
    <CustomAccordion
      AccordionSummary={<Typography variant="h6">Cursos</Typography>}
      AccordionProps={{ defaultExpanded: true }}
    >
      <FormControl fullWidth>
        <Select
          name="selectedCourses"
          value={values}
          onChange={(event) => setValues(event.target.value)}
          multiple
          renderValue={(selected) => (
            <Box display="flex" flexWrap="wrap">
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={
                    props.courses.filter(
                      (courseData) => courseData.ID === value
                    )[0].TITLE
                  }
                  className={classes.chip}
                />
              ))}
            </Box>
          )}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 525,
              },
            },
            anchorOrigin: { vertical: "top", horizontal: "center" },
            transformOrigin: { vertical: "bottom", horizontal: "center" },
            getContentAnchorEl: null,
          }}
        >
          {props.courses.map((courseData) => (
            <MenuItem
              classes={{ selected: classes.selected }}
              key={courseData.ID}
              value={courseData.ID}
              disabled={
                (!values.includes(courseData.ID) &&
                  values.length >= props.event.UF_CRM_1603322516) ||
                !Boolean(Number(courseData.UF_CRM_1612811807))
              }
            >
              {courseData.UF_CRM_1612806523
                ? courseData.TITLE 
                // +
                //   " - " +
                //   new Date(courseData.UF_CRM_1612806523)
                //     .toLocaleString("pt-BR")
                //     .replace(" ", " às ")
                //     .replace(":", "h")
                //     .slice(0, -3)
                : courseData.TITLE}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </CustomAccordion>
  ) : (
    <CustomAccordion disabled />
  );
};

export default CoursePanel;
