import React from "react";

import Box from "@material-ui/core/Box";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import KeyboardDatePicker from "../container/KeyboardDatePicker";

const CreditCardPaymentInputCollection = (props) => {
  const creditCardPaymentData = props.storedPaymentData
    .filter(
      (paymentInformation) =>
        Object.values(paymentInformation.PROPERTY_450)[0] ===
        props.paymentMethod
    )
    .slice(-1)[0];

  return (
    <Box marginY={1} width="100%">
      <Grid container spacing={2}>
        <Grid item xs sm md lg>
          <FormControl fullWidth>
            <TextField
              name="creditCardName"
              label="Nome do titular"
              helperText="Nome igual ao presente no cartão"
              defaultValue={
                creditCardPaymentData
                  ? creditCardPaymentData.PROPERTY_494
                    ? Object.values(creditCardPaymentData.PROPERTY_494)[0]
                    : ""
                  : ""
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs sm md lg xl>
          <FormControl fullWidth>
            <TextField
              name="creditCardNumber"
              label="Número"
              defaultValue={
                creditCardPaymentData
                  ? creditCardPaymentData.PROPERTY_454
                    ? Object.values(creditCardPaymentData.PROPERTY_454)[0]
                    : ""
                  : ""
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs sm md lg xl>
          <FormControl fullWidth>
            <TextField
              name="cpf"
              label="CPF do titular"
              defaultValue={
                creditCardPaymentData
                  ? creditCardPaymentData.PROPERTY_452
                    ? Object.values(creditCardPaymentData.PROPERTY_452)[0]
                    : ""
                  : ""
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs sm md lg xl>
          <FormControl fullWidth>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                name="expireDate"
                variant="inline"
                format="MM/yyyy"
                label="Data de vencimento"
                disableToolbar
                autoOk
                hideTabs
                invalidDateMessage="Data inválida"
                views={["month", "year"]}
                state={
                  creditCardPaymentData
                    ? creditCardPaymentData.PROPERTY_456
                      ? Object.values(creditCardPaymentData.PROPERTY_456)[0]
                      : null
                    : null
                }
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>

        <Grid item xs sm md lg xl>
          <FormControl fullWidth>
            <InputLabel>Bandeira</InputLabel>
            <Select
              name="creditCardBrand"
              defaultValue={
                creditCardPaymentData
                  ? creditCardPaymentData.PROPERTY_492
                    ? Object.values(creditCardPaymentData.PROPERTY_492)[0]
                    : ""
                  : ""
              }
            >
              {Object.entries(props.creditCardBrands).map(
                (creditCardBrand, index) => (
                  <MenuItem key={index} value={creditCardBrand[0]}>
                    {creditCardBrand[1]}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
          <FormControl fullWidth>
            <TextField
              name="cvv"
              label="CVV"
              defaultValue={
                creditCardPaymentData
                  ? creditCardPaymentData.PROPERTY_458
                    ? Object.values(creditCardPaymentData.PROPERTY_458)[0]
                    : ""
                  : ""
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreditCardPaymentInputCollection;
