import React from 'react';

import { KeyboardDateTimePicker } from '@material-ui/pickers/';

const KeyboardDateTimePickerStateful = (props) => {
  const [inputValue, setInputValue] = React.useState(props.initialvalue);

  return (
    <KeyboardDateTimePicker
      {...props}
      value={inputValue}
      onChange={setInputValue}
    />
  );
};

export default KeyboardDateTimePickerStateful;
