import React from "react";

import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import CustomAccordion from "./CustomAccordion";

const DollarPanel = () => {
  return (
    <CustomAccordion
      AccordionSummary={<Typography variant="h6">Dólar</Typography>}
      AccordionProps={{ defaultExpanded: true }}
    >
      <TextField
        label="Preço do dólar"
        name="dollarPrice"
        fullWidth
        InputProps={{
          startAdornment: <InputAdornment position="start">US$</InputAdornment>,
        }}
      />
    </CustomAccordion>
  );
};

export default DollarPanel;
